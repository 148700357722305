import React from "react";
import Header from "src/components/Header";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <Header></Header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="active first overview">
                <a href="/feiten-over-diarree/"> Feiten over diarree</a>
              </li>
              <li>
                <a href="/feiten-over-diarree/tips-voor-de-behandeling-van-diarree/">
                  Tips voor behandeling
                </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/oorzaken-van-diarree/">
                  Oorzaken van diarree
                </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/stress-en-diarree/">
                  Stress en diarree
                </a>
                <ul>
                  <li className="first">
                    <a href="/feiten-over-diarree/stress-en-diarree/hoge-werklast/">
                      Hoge werkdruk
                    </a>
                  </li>
                  <li className="last">
                    <a href="/feiten-over-diarree/stress-en-diarree/diarree-examen/">
                      Examensituaties
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="/feiten-over-diarree/buikgriep/">
                  Virale of bacteriële infectie
                </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/norovirus/">Norovirus</a>
              </li>
              <li>
                <a href="/feiten-over-diarree/diarree-tijdens-uw-menstruatie/">
                  Diarree tijdens de menstruatie
                </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/chronische-diarree/">
                  Chronische Diarree
                </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/eenartsraadplegen/">
                  Een arts raadplegen?
                </a>
              </li>
              <li className="last">
                <a href="/feiten-over-diarree/gezonde-eetgewoonten/">
                  Gezonde eetgewoonten
                </a>
                <ul>
                  <li className="first">
                    <a href="/feiten-over-diarree/gezonde-eetgewoonten/voedselallergie235n/">
                      Voedselallergieën
                    </a>
                  </li>
                  <li>
                    <a href="/feiten-over-diarree/gezonde-eetgewoonten/voedselvergiftiging/">
                      Voedselvergiftiging
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
            <div className="widget">
              <div className="inner">
                <h4>Tips bij diarree</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/teaser_durchfallmythen.jpeg"
                    width="170"
                    height="102"
                    alt="Tips bij diarree"
                  />
                </div>
                <p>
                  Rond het behandelen van de symptomen van diarree bestaan er
                  nog vele misverstanden en&nbsp;mythes. Hier vind je enkele
                  tips over het behandelen van de symptomen van diarree.
                </p>
                <a href="/feiten-over-diarree/tips-voor-de-behandeling-van-diarree/">
                  Tips bij diarree
                </a>
              </div>
            </div>
          </div>
          <div className="page nine columns page-6">
            <h1>Oorzaken diarree en behandeling</h1>
            <div className="img">
              <img
                src="/assets/files/pages/durchfallmythen.jpeg"
                width="701"
                height="289"
                alt="Feiten over diarree"
              />
            </div>
            <div className="intro row">
              <div className="three columns">
                <h2>Feiten over diarree</h2>
              </div>
              <div className="nine columns">
                <p>
                  <strong>Diarree</strong> is één van de meest voorkomende
                  kwalen. Iedereen kan met de ongemakken te maken krijgen die
                  ons in ons dagelijks leven kunnen verstoren.
                </p>
              </div>
            </div>
            <p>
              Er wordt een onderscheid gemaakt tussen{" "}
              <strong>acute diarree</strong> en{" "}
              <strong>chronische diarree</strong>. In beide gevallen gaat het
              grotendeels om dezelfde symptomen: frequente en&nbsp; losse,
              waterige ontlasting, die gepaard kan gaan met buikpijn door
              gasvorming, krampen of een opgeblazen gevoel. Het verschil ligt in
              de duur van de <strong>symptomen van diarree</strong>.
            </p>
            <h3>Acute diarree</h3>
            <p>
              <strong>Acute diarree</strong>&nbsp;treedt plotseling op en is
              meestal van korte duur. Na een paar dagen gaat deze vorm van
              diarree meestal spontaan weer over. In de meeste gevallen kan deze
              vorm van <strong>diarree</strong> met een diarreemiddel zoals{" "}
              <a href="/imodium-behandelen-diarree/">IMODIUM®</a> behandeld
              worden.
              <br />
              Dit helpt de diarree snel te verlichten, het normale ritme van je
              spijsverteringsstelsel weer te herstellen en daarmee de verstoring
              van je dagelijkse leven tot een minimum te beperken.
            </p>
            <p>&nbsp;</p>
            <h3
              style={{
                "-webkit-text-align": "justify",
                "text-align": "justify"
              }}
            >
              Chronische diarree
            </h3>
            <p>
              <strong>Chronische diarree</strong>&nbsp;houdt lang aan of treedt
              herhaaldelijk op. Misschien niet elke dag, maar anders dan{" "}
              <strong>acute diarree </strong>komt deze vorm van{" "}
              <strong>diarree</strong> steeds weer terug en lijkt nooit echt
              over te gaan.&nbsp;
              <br />
              Aangezien je lichaam door een waterige stoelgang de hele tijd
              belangrijke vloeistoffen en voedingsstoffen verliest, kun je
              verzwakken en uitdrogen. Het is van belang om je huisarts te
              raadplegen.
            </p>
            <p>Mogelijke oorzaken van chronische diarree zijn:</p>
            <ul>
              <li>Prikkelbare-darmsyndroom (PDS)</li>
              <li>De ziekte van Crohn en ulceratieve colitis</li>
              <li>HIV/AIDS-infecties en -medicijnen</li>
              <li>Kanker en kankerbehandelingen</li>
            </ul>
            <p>
              Hoewel het misschien een verontrustende tijd is, kan het ontdekken
              van de oorzaak van chronische diarree je inzicht geven in de
              manier om je weer beter te gaan voelen.
            </p>
            <p>
              <a href="/feiten-over-diarree/chronische-diarree/">
                Lees meer over chronische diarree
              </a>
            </p>
          </div>
        </div>
        <Footer></Footer>
        <div id="myModal" className="reveal-modal large">
          <div
            className="flash-wrapper"
            style={{
              "max-width": "100%",
              height: "236px",
              width: "428px",
              margin: "0 auto"
            }}
          >
            <div id="flash">
              <p>
                Your browser must have Adobe Flash installed to see this
                animation.
              </p>
            </div>
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="nl-BE" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
         <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script src="/assets/js/custom.js" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
         
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\ncookiesDirective('bottom',5,'/cookiebeleid');\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
